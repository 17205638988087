* {
  font-family: 'Arial';
  color: #e9edef
}

body {
  margin: 0;
}

.background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  object-fit: cover;
}

.background:after {
  content: '';
  position: absolute;
  width: 10%;
  height: 100%;
  background: #000;
  opacity: .5;
}

.chat {
  max-width: 40rem;
  margin: 1em auto 5em;
  overflow: hidden;
  padding: .5em;
}

/* Date */
.date {
  text-align: center;
}

.date span,
.master-date span {
  background: #3993c0;
  border-radius: .2em;
  padding: .1em .7em;
  position: relative;
  z-index: 1;
}

.master-date {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 1;
}

/* Message */
.message__wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: .2em;
}

.message__wrap.out {
  align-items: flex-end;
}

.message__wrap.last {
  margin-bottom: 1em;
}

.message {
  position: relative;
  border-radius: .25em;
  width: auto;
  max-width: min(80%, 30em);
  padding: .2em .5em;
  box-shadow: 4px 4px 13px 1px #0000006e
}

.in .message {
  background-color: #202c33;
}

.out .message {
  background-color: #005c4b;
}


.first .message:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border-bottom: 10px solid transparent;
}

.first.out .message:after {
  right: -3px;
  border-left: 6px solid #005c4b;
}

.first.in .message:after {
  left: -3px;
  border-right: 6px solid #202c33;
}

.message__time {
  color: hsla(0,0%,100%,0.6);
  text-align: right;
  font-size: .7rem;
}

/* Button */
.search-button {
  position: fixed;
  bottom: .5em;
  background: #3993c0;
  border: none;
  border-radius: .2em;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.3em 1em;
  font-size: 1em;
}